import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { DialogErrorComponent } from '@common/components/dialog-error.component';
import { DialogService } from '@common/services/dialog.service';
import { WebsocketService } from '@common/services/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class DialogErrorHandler implements ErrorHandler {
    private isDialogOpen = false;

    constructor(
        private dialogService: DialogService,
        private websocketService: WebsocketService,
        private t: TranslateService
    ) {}

    handleError(error): void {
        if (!error || error.rejection?.error === 'Token-Expired') return;
        if (error.rejection?.httpResponse) error = error.rejection.httpResponse.response; // breeze query error

        if (error.rejection?.error?.validationErrors) {
            this.handleServerSideError(error.rejection);
        } else if (error instanceof HttpErrorResponse) {
            this.handleServerSideError(error);
        } else if (error.rejection instanceof HttpErrorResponse) {
            this.handleServerSideError(error.rejection);
        } else {
            this.handleClientSideError(error);
        }
    }

    private handleServerSideError(response: HttpErrorResponse) {
        let error = response.error || {};

        if (error.validationErrors) {
            const validationErrors = error.validationErrors;
            return this.openErrorDialog(
                validationErrors.map((e) => e.errorMessage).join('\n'),
                null,
                true,
                validationErrors.map((e) => e.propertyName)
            );
        } else if (error.entityErrors) {
            return this.handleEntityErrors(error.entityErrors);
        }

        if (_.isString(error) && error[0] === '{') {
            // breeze command with status 500
            try {
                error = JSON.parse(error);
            } catch {
                /* empty */
            }
        }

        this.openErrorDialog(error.errorMessage, error.exception, true);
    }

    private handleEntityErrors(entityErrors: any[]) {
        return this.openErrorDialog(entityErrors.map((e) => e.errorMessage).join('\n'), null, true);
    }

    private handleClientSideError(error: Error) {
        console.error(error);
        this.remoteLogError(error);
        this.openErrorDialog(error.message, error.stack, false);
    }

    private openErrorDialog(errorMessage, stackTrace, serverSide, propertyNames = null) {
        if (this.isDialogOpen) return;
        this.isDialogOpen = true;

        if (propertyNames?.includes('Password')) {
            //* Split the error message by newline, translate each part separately, and then merge them back
            // const splitMessage = errorMessage.split('\n');
            // errorMessage = splitMessage.map((s) => this.t.instant(s)).join('\n');
        }

        this.dialogService
            .open({
                template: {
                    content: DialogErrorComponent,
                    data: {
                        message: errorMessage,
                        isServerSideError: serverSide,
                        stackTrace
                    }
                },
                dialogSettings: {
                    width: 720,
                    height: 520
                }
            })
            .finally(() => (this.isDialogOpen = false));
    }

    remoteLogError(error) {
        if (error.toString().match('Cannot read property')) return;
        this.websocketService.error(error.toString()).catch((err) => console.error(err));
    }
}
