<ngx-loading-bar height="4px" color="#fff" ref="router" [includeSpinner]="false"></ngx-loading-bar>
<ngx-loading-bar height="4px" color="#fff" ref="html" [includeSpinner]="false"></ngx-loading-bar>
<ngx-spinner type="ball-clip-rotate-multiple" bdColor="rgba(0,0,0,0.8)" size="large" color="#fff" [fullScreen]="true">
    <p class="text-white">
        {{ 'Please be patient while we process the request. This may take a while.' | translate }}
    </p>
</ngx-spinner>
<div kendoDialogContainer></div>
<div
    class="flex h-full overflow-y-auto overflow-x-clip bg-background bg-cover"
    [ngClass]="{ 'p-2': isAuthenticated }"
    [ngStyle]="{
        'background-image': !currentUser
            ? 'url(' + backgroundImageUrl + ')'
            : 'url(' + '/assets/img/background.svg' + ')'
    }">
    @if (isAuthenticated) {
        <app-sidebar></app-sidebar>
    }
    @if (environmentName) {
        <div
            class="title fixed right-8 top-2 z-10 select-none text-nowrap px-2 text-lg"
            kendoTooltip
            position="top"
            title="{{ environmentName | translate }}"
            [attr.style]="environmentColor">
            •
        </div>
    }
    <div class="h-full min-w-0 flex-1">
        <main class="h-full">
            <kendo-splitter class="h-full w-full border-none bg-transparent" #consoleSplitter orientation="vertical">
                <kendo-splitter-pane
                    class="bg-background px-2 text-foreground"
                    [ngStyle]="{
                        'background-image': 'url(' + backgroundImageUrl + ')',
                        'background-size': 'cover'
                    }">
                    <router-outlet></router-outlet>
                </kendo-splitter-pane>
            </kendo-splitter>
        </main>
    </div>
</div>
